/**
 * Loads the page data from the website API.
 *
 * @returns {Object} Containing the page and the fetchPage method.
 */
export const usePage = () => {
	const page = useState('page', () => {});
	const { locale, defaultLocale } = useI18n();

	/**
	 * Sets the page state.
	 *
	 * @param {*} data The page data.
	 *
	 * @returns {void} Nothing.
	 */
	const setPage = (data) => {
		page.value = data;
	};

	/**
	 * Fetches the page data from the API and sets it in the page state.
	 *
	 * @returns {void} Nothing.
	 */
	const fetchPage = async () => {
		const route = useRoute();

		const pageFilename = useFilename(route, locale.value);

		const key = `page${route.path}`;
		let pageData = {};

		let cachedData = useNuxtData(key);
		if (cachedData.data.value) {
			setPage(cachedData.data);
		} else if (pathContainsDefaultLocale(route)) {
			// Fetch 404 page to avoid duplicate content, the default locale is served on the root path
			pageData = await fetch404Page(key);

			setPage(pageData.data);
		} else {
			pageData = await useWebsiteFetch('pages', {
				query: { filename: pageFilename, language: locale.value },
				key,
			});

			// No page found, so fetch the 404 page
			if (!Object.keys(pageData.data.value).length) {
				pageData = await fetch404Page(key);
			}

			setPage(pageData.data);
		}
	};

	/**
	 * Fetches the 404 page from the API.
	 *
	 * @param {String} key The key to use for the cache.
	 *
	 * @returns {Object} The page data.
	 */
	const fetch404Page = async (key) => {
		let result;

		result = await useWebsiteFetch('pages', {
			query: { filename: '404', language: locale.value },
			key,
			refreshCache: true,
		});

		const event = useRequestEvent();
		setResponseStatus(event, 404);

		return result;
	};

	/**
	 * Checks if the route path contains the default locale.
	 *
	 * @param {Object} route The route object
	 *
	 * @returns {Boolean} Whether or not the route path contains the default locale.
	 */
	const pathContainsDefaultLocale = (route) => {
		if (route.path === `/${defaultLocale}` || route.path.startsWith(`/${defaultLocale}/`)) {
			return true;
		}

		return false;
	};

	return {
		page,
		fetchPage,
	};
};
